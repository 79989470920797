/* You can add global styles to this file, and also import other style files */
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import 'src/assets/styles/_constants.scss';

$text-light-color: #e0e0e0;
$text-dark-color: #444;
$button-base-color: black;
$button-hover-color: black;
$base-color: #576c7e;
$base-color-2: #789ab8;
$heading-color: #606060;

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem 1rem !important;
}
.p-datatable .p-datatable-thead > tr > th {
  color: whitesmoke !important;
  background-color: $base-color !important;
}
.p-datatable .btn {
  color: #6c757d;
}
.p-datatable {
  .p-datatable .p-datatable-thead > tr > th {
    color: whitesmoke !important;
    background-color: $button-hover-color !important;
  }
}

::ng-deep .p-dropdown-panel,
::ng-deep .p-treeselect-panel,
::ng-deep .p-autocomplete-panel {
  box-shadow: #00000040 0px 0px 10px !important;
}

html,
body {
  min-height: 100vh;
  color: $text-dark-color;
}
h2 {
  color: $heading-color;
}
.btn-primary {
  background-color: $button-base-color !important;
  color: white !important;
  border-color: black !important;

  .btn-primary:hover {
    background-color: $button-hover-color !important;
    color: white !important;
  }
}
.btn-secondary {
  border-color: #000 !important;
  background: #FFF !important;
  color: black !important;

  .btn-secondary:hover {
    background: #FFF !important;
    color: black !important;
  }
}

.p-button {
  background-color: rgba(0, 0, 0, 0.05);
  color: black;
  border: 1px solid black;
  transition: background-color 0.25s linear;

  &:hover {
    background-color: $button-hover-color;
    color: white;
  }

  &:enabled:hover {
    background-color: $button-hover-color;
    color: white;
    border: 1px solid black;
  }

  &:active {
    background-color: map-get($blue-gray, std);
    box-shadow: 0 0.1rem map-get($blue-gray, lightest);
    transform: translateY(0.1rem);
  }
}

.p-fileupload .p-fileupload-content {
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.p-fileupload-choose:not(.p-disabled):hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: black;
  border: 1px solid black;
}
.p-breadcrumb {
  background-color: #aaaaaa00;
  padding: 0rem 1rem 0.5rem;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: $button-base-color;
  font-weight: 600;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: $button-base-color;
}
.p-dropdown {
  width: 100%;
}
.p-dialog-content {
  overflow-y: visible !important;
  padding: 0;
}
.p-autocomplete {
  width: 100%;
}
.p-treeselect {
  width: 100%;
}
.p-datatable table {
  border: 1px solid #ccc;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: white;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: white;
}
.p-tooltip {
  pointer-events: none;
}
.p-paginator-first {
  color: black !important;
}
.p-paginator-prev {
  color: black !important;
}
.p-paginator-next {
  color: black !important;
}
.p-paginator-last {
  color: black !important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  color: black !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(map-get($blue-gray, light), 0.25);
  border-color: rgba(map-get($blue-gray, light), 0.25);
  color: black !important;
}
.p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(map-get($blue-gray, light), 0.25);
}